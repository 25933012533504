import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsPartial,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/components.yml';

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Data Card"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Modules"
          tierThree="Data Card"
        />
        <PageNavigation
          links={[
            'View Modes',
            'Accessibility',
            'Microcopy',
            'Usage',
            'Related',
            'Platform Support',
          ]}
        />
        <Section title="View Modes">
          <Paragraph>
            Data cards can be viewed in the <strong>collapsed</strong>,{' '}
            <strong>preview</strong>, <strong>expanded</strong>, and{' '}
            <strong>full</strong> view states of the{' '}
            <Link href="../../design">module system</Link>.
          </Paragraph>

          <SectionSubhead>Collapsed</SectionSubhead>
          <Paragraph>
            This is the sleekest presentation of the data card and provides only
            the title of the data it contains and an optional
            mini-visualization.
          </Paragraph>

          <SectionSubhead>Preview</SectionSubhead>
          <Paragraph>
            This is the most common view of the data card. They are usually
            present as a collection within a{' '}
            <Link href="../../card-group/design">card group</Link>. It shares
            the title as well as the most important pieces of data the card
            contains. Each visualization will automatically show what is most
            relevant. This includes any items that may be selected within the
            data.
          </Paragraph>

          <SectionSubhead>Expanded</SectionSubhead>
          <Paragraph>
            When in the expanded view mode, the data card will display all items
            included in the content area. It will fill the entire module to
            bring more focus to the data set.
          </Paragraph>

          <SectionSubhead>Full</SectionSubhead>
          <Paragraph>
            When in the full view mode, the data card will display all items
            included in the content area. It will fill the entire screen, and
            should only be used for processes that require complete focus. This
            view mode doesn't currently support data visualizations.
            {/* TODO: Link "data visualizations" to section in overview page once it exists */}
          </Paragraph>
        </Section>

        <Section title="Accessibility">
          <SectionSubhead>Keyboard Navigation</SectionSubhead>
          <Paragraph>
            Tab navigation is fully supported by default. Users can press{' '}
            <KeyboardShortcut>tab</KeyboardShortcut> to navigate to data cards
            and within header or body visualizations, and{' '}
            <KeyboardShortcut>Enter</KeyboardShortcut> to mimic pressing the
            header.
          </Paragraph>
          <AccessibilityAuditComponentResultsPartial componentName="Modules / Data Card" />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Header Titles</SectionSubhead>
          <Paragraph>
            The header title should be concise and easily describe the set of
            data it contains. In predetermined data cards, write them in title
            case, but don't worry about punctuation. Avoid using acronyms
            wherever possible. When the data set is titled by the user (such as
            an ungrouped stat name) display the text string as they've entered
            it.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Module System</SectionSubhead>

          <Paragraph>
            Data cards are part of the module system and should only be used
            with card groups and data visualizations.
          </Paragraph>

          <DontDo
            dontText="use data cards outside of card groups."
            doText="include a data visualization within the content area."
            imgFilename="datacard-module"
          />

          <DontDo
            dontText="nest data cards within data cards."
            doText="group relevant data cards in a logical manner."
            imgFilename="datacard-nest"
          />

          <DontDo
            dontText="place mismatched visualizations within data cards."
            doText="use the appropriate data visualization to portray the data"
            imgFilename="datacard-visualizations"
          />
        </Section>

        <Section title="Related">
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design">Module System</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../card-group/design">Card Group</Link>
          </h3>
          <h3>
            <Link href="../../design#Choosing%20a%20Visualization">
              Data Visualizations
            </Link>
          </h3>
        </Section>

        <Section title="Platform Support">
          <Paragraph>
            The data card is available for React and React Native.
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
